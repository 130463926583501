<template>
  <div>
    <el-menu
      :default-active="this.$route.path"
      mode="horizontal"
      text-color="#544646"
      active-text-color="#fb3434"
      @select="handleSelect"
    >
      <el-menu-item v-for="(item, i) in navList" :index="item.path" :key="i">{{item.title}}</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navList: [
        { path: '/index', title: '首页' },
        // { path: '/shop', title: '商城' },
        { path: '/orderAdmin/purchase', title: '礼品商城' },
        { path: '/member', title: '会员中心' },
        // { path: '/promotion', title: '推广大使' },
        { path: '/fastmail', title: '快递价格' },
        { path: '/join', title: '分站开通' },
        // { path: '/saleOrder', title: '百万鱼塘' },
        // { path: '/notice', title: '系统公告' },
        // { path: '/video', title: '视频教程' },
        { path: '/api', title: 'API接口' }
      ]
    }
  },
  created () {
    if (this.isMain) {
      this.navList = [
        { path: '/index', title: '首页' },
        // { path: '/shop', title: '商城' },
        { path: '/orderAdmin/purchase', title: '礼品商城' },
        { path: '/member', title: '会员中心' },
        { path: '/promotion', title: '推广大使' },
        { path: '/fastmail', title: '快递价格' },
        { path: '/join', title: '分站开通' },
        // { path: '/dyScore', title: '抖店提分' },
        // { path: '/saleOrder', title: '百万鱼塘' },
        // { path: '/notice', title: '系统公告' },
        // { path: '/video', title: '视频教程' },
        { path: '/api', title: 'API接口' }
      ]
    }
  },
  methods: {
    handleSelect (index) {
      // https://v.qq.com/x/page/e3216wwprvt.html
      if (index === '/api') {
        // window.open(this.$store.getters.getSiteInfo.api_service, '_blank')
        window.open('http://' + window.location.host + '/apiServer.html#f5048ba8-68ac-11eb-a95d-1c34da7b354c', '_blank')
      } else if (index === '/video') {
        window.open('https://v.qq.com/x/page/e3216wwprvt.html', '_blank')
      } else {
        this.$router.push(index)
      }
    }
  }
}
</script>

<style scoped src="styles/components/navbarmenu.css">
</style>
