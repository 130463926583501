<template>
  <div class="logo">
    <img :src="$store.getters.getSiteInfo.logal"/>
    <h2 :style="font">{{$store.getters.getSiteInfo.name}}</h2>
  </div>
</template>

<script>
export default {
  props: {
    font: { default: 'font-size: 30px;' }
  }
}
</script>

<style scoped src="styles/components/navbarlogo.css">
</style>
